import React from 'react';

import Step from '../../../components/step';
import Section from '../../../components/section';
import PriceDisplay from '../Pricing/PriceDisplay';

const HowCreditsWork = ({
  title,
  description,
  buyTitle,
  buyText,
  payTitle,
  payText,
  useTitle,
  useText,
}) => {
  return (
    <div className="pricing-how">
      <Section title={title} description={description} className="how">
        <Step
          className="how__step"
          title={buyTitle}
          content={buyText}
          counter={'01'}
        >
          <PriceDisplay />
        </Step>
        <Step
          className="how__step"
          title={payTitle}
          content={payText}
          counter={'02'}
        ></Step>
        <Step
          className="how__step"
          title={useTitle}
          content={useText}
          counter={'03'}
        ></Step>
      </Section>
    </div>
  );
};

export default HowCreditsWork;
