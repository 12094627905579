import { Typography } from '@deeptrue-frontend/ui';
import React from 'react';
import { AnchorLink as Link } from 'gatsby-plugin-anchor-links';
import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  height: 25rem;
  margin-top: 3.5rem;

  @media (min-width: 960px) {
    height: 26rem;
  }
`;

const Title = styled(Typography)`
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 2rem;
  text-align: center;

  @media (min-width: 960px) {
    font-size: 3.5rem;
    line-height: 4.5rem;
    margin-top: 5rem;
  }
`;

const Subtitle = styled(Typography)`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 0 1rem;

  @media (min-width: 960px) {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 400;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 1rem;
  background-color: #1656e9;
  color: #fbfbfb;
  border-radius: 1.5rem;
  padding: 0.875rem 1.25rem;
  font-weight: 600;
  transition: all 0.2s ease-in;
  display: inline-block;

  &:hover {
    color: white;
    background-color: #0f44bb !important;
  }
`;

interface Props {
  title: string;
  subTitle: string;
  creditsText: string;
  ctaText?: string;
  ctaUrl?: string;
  children: any;
}

const Hero = ({
  title,
  subTitle,
  creditsText,
  ctaText,
  ctaUrl,
  children,
}: Props) => {
  return (
    <Header>
      <Title color="#253858" fontWeight={400}>
        {title}
      </Title>
      <Subtitle color="#5C6370">{subTitle}</Subtitle>
      <Subtitle color="#253858">{creditsText}</Subtitle>
      {ctaText && ctaUrl && <StyledLink to={ctaUrl}>{ctaText}</StyledLink>}
      {children}
    </Header>
  );
};

export default Hero;
