import React from 'react';
import styled from 'styled-components';
import CurrencyDropdown from '../../currencyDropdown';
import {
  PricingContext,
  getCurrencyName,
} from '../../../providers/PricingProvider';

const Display = styled.div`
  display: flex;
`;

const Description = styled.div`
  color: #5c6370;
  align-items: center;
  display: flex;
  padding: 1rem;
`;

const Price = styled.div`
  color: #144bb8;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  border: 1px solid #e3e5e8;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgb(63 63 68 / 4%), 0px 1px 1px rgb(63 63 68 / 8%);
  border-radius: 2px;
  width: 20rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;

  .m-dropdown__value,
  .m-dropdown__placeholder {
    display: flex;
    align-items: center;
  }
  .currency-dropdown {
    width: 8rem;
  }
  @media (max-width: 320px) {
    width: 16rem;
  }
`;

const PriceDisplay = () => {
  return (
    <Wrapper>
      <PricingContext.Consumer>
        {({ pricingData }) => {
          return (
            <Display>
              <Description>Price per credit</Description>
              <Price>
                {
                  pricingData.currencyRates[
                    getCurrencyName(pricingData.currentCurrency)
                  ]
                }
              </Price>
            </Display>
          );
        }}
      </PricingContext.Consumer>

      <CurrencyDropdown />
    </Wrapper>
  );
};

export default PriceDisplay;
