import React from 'react';
import styled from 'styled-components';
import Section from '../../../components/section';

import Accordion from '../../accordion';

const Content = styled.div`
  width: 1px;
  height: calc(100% - 1.5rem);
  top: 3rem;
  left: -1.5rem;
`;

type PricingFaqProps = {
  title: string;
  data: any;
};
//list
const PricingFaq = ({ title, data }: PricingFaqProps) => {
  return (
    <Section title={title}>
      <Content className="pricingFaq">
        {data.length > 0 &&
          data.map((item, idx_content) => {
            return (
              <Accordion
                key={`accordion-group_${idx_content}`}
                question={item.question}
                answer={item.answer_content.html}
              />
            );
          })}
      </Content>
    </Section>
  );
};

export default PricingFaq;
