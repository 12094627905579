import React from 'react';
import styled from 'styled-components/macro';

type TestimonyProps = {
  quote: string;
  imageUrl: string;
  name: string;
  position: string;
  logoUrl: string;
};

const Logo = styled.img`
  margin-top: 1rem;
  width: auto;
  height: auto;
`;

const Testimony = ({
  quote,
  imageUrl = '',
  name,
  position,
  logoUrl = '',
}: TestimonyProps) => {
  return (
    <article className="m-testimony">
      <blockquote className="m-testimony__quote">“{quote}”</blockquote>
      <figure className="m-testimony__author">
        <img
          className="m-testimony__image"
          src={imageUrl}
          alt={name}
          loading="lazy"
        />
        <figcaption className="m-testimony__info">
          {name} <br />
          {position} <br />
          {logoUrl && <Logo src={logoUrl} alt="logo" loading="lazy" />}
        </figcaption>
      </figure>
    </article>
  );
};

export default Testimony;
