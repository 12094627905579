import React from 'react';
import styled from 'styled-components';
import Title from '../Base/Title';
import Section from '../Base/Section';
import { Typography } from '@deeptrue-frontend/ui';

const Content = styled.div`
  width: 100%;
  margin: 1rem;
  padding: 0 1.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (min-width: 800px) {
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2.5rem 0;
  position: relative;

  @media (min-width: 800px) {
    max-width: 20rem;
    margin: 2.5rem 1rem;
  }
`;

const BoxTitle = styled(Typography)`
  font-weight: 500;
  font-size: 1.125rem;
  color: #2c4064;
  margin-top: 1rem;
`;
const BoxDescription = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  color: #253858;
  margin-top: 1rem;
`;
const Icon = styled.img`
  width: 2rem;
  height: 2rem;
`;

const Line = styled.div`
  position: absolute;
  width: 1px;
  height: calc(100% - 1.5rem);
  top: 3rem;
  left: -1.5rem;
  background-color: #e3e5e8;

  span {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2px;
    background-color: #144bb8;
  }
`;

interface Props {
  tag: string;
  title: string;
  data: any;
}

const KeyBenefitsSection = ({ tag, title, data }: Props) => {
  return (
    <Section alignItems="center" fullWidth padding="4.5rem 1.5rem">
      <Title noLine tag={tag} title={title} alignItems="center" />
      <Content>
        {data.length &&
          data.map((item, idx) => (
            <Box key={`${idx}_${item.why_content_title}`}>
              <Icon
                src={item.why_content_icon.url}
                alt={item.why_content_title}
              />
              <Line>
                <span />
              </Line>
              <BoxTitle>{item.why_content_title}</BoxTitle>
              <BoxDescription>{item.why_content_description}</BoxDescription>
            </Box>
          ))}
      </Content>
    </Section>
  );
};

export default KeyBenefitsSection;
