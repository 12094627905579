import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Section from '../Base/Section';
import Testimony from '../../../components/testimony';
import Title from '../Base/Title';

const Content = styled.div`
  width: 100%;
  margin: 2.5rem auto;
  position: relative;

  .react-multi-carousel-list {
    padding-bottom: 1.5rem;
  }
  .react-multi-carousel-item {
    padding: 0 20px;
  }

  .react-multi-carousel-track {
    margin: 0 auto;
  }

  .m-testimony__author {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 960px) {
    .m-testimony__author {
      text-align: left;
      display: flex;
      flex-direction: row;
    }
    .m-testimony__info {
      margin-top: 0;
    }
  }
`;

type TestimonialType = {
  client_name: string;
  client_position: string;
  client_quote: string;
  client_image: {
    url: string;
  };
};

type ClientsProps = {
  testimonialsTitle: string;
  testimonialsSubtitle: string;
  testimonials: TestimonialType[];
};

const Clients = ({
  testimonialsTitle,
  testimonialsSubtitle,
  testimonials,
}: ClientsProps) => {
  return (
    <div style={{ marginTop: '4rem' }}>
      <Section alignItems="center">
        <Title
          noLine
          tag={testimonialsTitle}
          title={testimonialsSubtitle}
          alignItems="center"
        />
        <Content>
          <div className="m-carousel">
            <Carousel
              showDots={true}
              responsive={{
                tablet: {
                  breakpoint: { min: 960, max: 8000 },
                  items: 2,
                },
                mobile: {
                  breakpoint: { max: 960, min: 0 },
                  items: 1,
                },
              }}
            >
              {testimonials &&
                testimonials.map((testimonial: TestimonialType) => (
                  <Testimony
                    key={testimonial.client_name}
                    name={testimonial.client_name}
                    position={testimonial.client_position}
                    quote={testimonial.client_quote}
                    imageUrl={testimonial.client_image.url}
                  />
                ))}
            </Carousel>
          </div>
        </Content>
      </Section>
    </div>
  );
};

export default Clients;
