import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import Title from '../Base/Title';
import Section from '../Base/Section';
import CardExpanded from '../../../components/cardExpanded';

const Content = styled.div`
  width: 100%;
  margin: 2.5rem auto 4rem;
  position: relative;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .react-multi-carousel-list {
    padding-bottom: 1.5rem;
  }
  .react-multi-carousel-item {
    padding: 0 20px;
  }

  .react-multi-carousel-track {
    margin: 0 auto;
  }
`;

const InsightTools = ({ title, tag, tools, currency, currencyRate }) => {
  return (
    <Section
      alignItems="center"
      fullWidth
      padding="4.5rem 1.5rem"
      bgColor="#001F33"
      id="insight-tools"
    >
      <Title
        tag={tag}
        title={title}
        noLine
        alignItems="center"
        titleColor="white"
        tagColor="#759EF0"
      />
      <Content className="m-carousel">
        <Carousel
          showDots={true}
          responsive={{
            desktop: {
              breakpoint: { max: 8000, min: 1200 },
              items: 3,
            },
            tablet: {
              breakpoint: { min: 960, max: 1200 },
              items: 3,
            },
            mobile: {
              breakpoint: { max: 960, min: 700 },
              items: 2,
            },
            small: {
              breakpoint: { max: 700, min: 0 },
              items: 1,
            },
            x_small: {
              breakpoint: { max: 480, min: 0 },
              items: 1,
            },
          }}
        >
          {tools &&
            tools.map((tool, idx) => {
              return (
                tool.is_tool_visible && (
                  <CardExpanded
                    key={`${idx}_${tool.tool_title}`}
                    title={tool.tool_title}
                    description={tool.tool_description}
                    cta_label={tool.tool_cta_text}
                    button_label={tool.tool_button_text}
                    price={Math.round(parseInt(tool.tool_price) * currencyRate)}
                    currency={currency}
                    starting={tool.tool_starting_text}
                    tool_slug={tool.tool_slug || ''}
                  />
                )
              );
            })}
        </Carousel>
      </Content>
    </Section>
  );
};

export default InsightTools;
