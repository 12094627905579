import React from 'react';
import Section from '../../../components/section';
import TwoColumnSection from '../../../components/twoColumnSection';
import PriceDisplay from '../Pricing/PriceDisplay';

const CalculatingPrice = ({ title, description, content }) => {
  return (
    <Section
      className="dt-calculating__section"
      title={title}
      description={description}
    >
      <TwoColumnSection
        title={content[0]?.calculating_content_title}
        description={content[0]?.calculating_content_description}
        cover={content[0]?.image.url}
        cta_label={content[0]?.calculating_content_cta_text}
        cta_url={`/products/customer-satisfaction`}
      >
        <PriceDisplay />
      </TwoColumnSection>
      <TwoColumnSection
        toggleDirection
        title={content[1]?.calculating_content_title}
        description={content[1]?.calculating_content_description}
        cover={content[1]?.image.url}
        cta_label={content[1]?.calculating_content_cta_text}
        cta_url={`/products/customer-satisfaction`}
      />
    </Section>
  );
};

export default CalculatingPrice;
