import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/sections/Pricing/Hero';
import WhyCreditsSection from '../components/sections/Pricing/WhyCredits';
import PricingFaq from '../components/sections/Pricing/PricingFaq';
import Clients from '../components/sections/Pricing/Clients';
import HowCreditsWork from '../components/sections/Pricing/HowCreditsWork';
import InsightTools from '../components/sections/Pricing/InsightTools';
import CalculatingPrice from '../components/sections/Pricing/CalculatingPrice';
import PriceDisplay from '../components/sections/Pricing/PriceDisplay';
import {
  PricingContext,
  getLowestToolPrice,
  currencySymbols,
  getCurrencyName,
} from '../providers/PricingProvider';

interface Props {
  pageContext: {
    language: string;
  };
  data: any;
}

const Pricing = ({ pageContext, data }: Props) => {
  const pricingDataPrismic =
    data.allPrismicGeneralPricingPage.edges[0].node.data;
  const allPrismicCommonInformation =
    data.allPrismicCommonInformation.edges[0].node.data;

  return (
    <PricingContext.Consumer>
      {({ toolsPricing, pricingData }) => {
        getLowestToolPrice(toolsPricing);
        if (!toolsPricing || !pricingData) return null;
        return (
          <Layout lang={pageContext.language}>
            <SEO title="Pricing" lang={pageContext.language} />
            <Hero
              title={pricingDataPrismic.main_title}
              subTitle={pricingDataPrismic.main_subtitle}
              creditsText={`${
                allPrismicCommonInformation.pricing_starting_at_text
              } ${Math.round(
                getLowestToolPrice(toolsPricing) *
                  pricingData.currencyRates[
                    getCurrencyName(pricingData.currentCurrency)
                  ]
              )} ${
                currencySymbols[getCurrencyName(pricingData.currentCurrency)]
              } ${allPrismicCommonInformation.pricing_per_tool_text}`}
              ctaText={pricingDataPrismic.main_cta_text}
              ctaUrl={'#insight-tools'}
            >
              <PriceDisplay />
            </Hero>
            <HowCreditsWork
              title={pricingDataPrismic.how_title}
              description={pricingDataPrismic.how_description}
              buyTitle={pricingDataPrismic.buy_title}
              buyText={pricingDataPrismic.buy_description}
              payTitle={pricingDataPrismic.pay_title}
              payText={pricingDataPrismic.pay_description}
              useTitle={pricingDataPrismic.use_title}
              useText={pricingDataPrismic.use_description}
            />
            <InsightTools
              title={`${pricingDataPrismic.tools_title} ${Math.round(
                getLowestToolPrice(toolsPricing) *
                  pricingData.currencyRates[
                    getCurrencyName(pricingData.currentCurrency)
                  ]
              )} ${
                currencySymbols[getCurrencyName(pricingData.currentCurrency)]
              }`}
              tag={pricingDataPrismic.tools_tagline}
              tools={pricingDataPrismic.tool_details}
              currency={
                currencySymbols[getCurrencyName(pricingData.currentCurrency)]
              }
              currencyRate={
                pricingData.currencyRates[
                  getCurrencyName(pricingData.currentCurrency)
                ]
              }
            />
            <CalculatingPrice
              title={pricingDataPrismic.calculating_title}
              description={pricingDataPrismic.calculating_description}
              content={pricingDataPrismic.calculating_content}
            />
            <WhyCreditsSection
              tag={pricingDataPrismic.why_tagline}
              title={pricingDataPrismic.why_title}
              data={pricingDataPrismic.why_content}
            />
            <PricingFaq
              title={pricingDataPrismic.body1[0].primary.section_title}
              data={pricingDataPrismic.body1[0].items || []}
            />
            <Clients
              testimonialsTitle={pricingDataPrismic.clients_tagline}
              testimonialsSubtitle={pricingDataPrismic.clients_title}
              testimonials={pricingDataPrismic.clients_testimonials}
            />
          </Layout>
        );
      }}
    </PricingContext.Consumer>
  );
};

export const query = graphql`
  query PricingQuery($prismicLang: String) {
    allPrismicGeneralPricingPage(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          data {
            calculating_content {
              image {
                url
              }
              calculating_content_cta_text
              calculating_content_description
              calculating_content_title
              calculating_content_cta_url
            }
            clients_testimonials {
              client_image {
                url
              }
              client_name
              client_position
              client_quote
            }
            why_content {
              why_content_icon {
                url
              }
              why_content_description
              why_content_title
            }
            main_cta_text
            buy_description
            buy_title
            calculating_description
            calculating_title
            clients_tagline
            clients_title
            credit_text
            how_description
            how_title
            main_subtitle
            pay_description
            pay_title
            price_text
            tool_details {
              tool_button_text
              tool_cta_text
              tool_description
              tool_price
              tool_starting_text
              tool_title
              tool_slug
              is_tool_visible
            }
            tools_tagline
            use_description
            tools_title
            use_title
            why_tagline
            why_title
            main_title
            body1 {
              ... on PrismicGeneralPricingPageDataBody1Section {
                id
                primary {
                  section_title
                }
                items {
                  answer_content {
                    html
                  }
                  question
                }
              }
            }
          }
        }
      }
    }
    allPrismicCommonInformation(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          lang
          data {
            pricing_per_tool_text
            pricing_starting_at_text
          }
        }
      }
    }
  }
`;

/* eslint-disable-next-line */
// @ts-ignore eslint-disable-line
export default withPrismicPreview(Pricing);
