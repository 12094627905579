import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Typography } from '@deeptrue-frontend/ui';
import { Icon } from '@deeptrue-frontend/ui';

const Article = styled.article`
  min-height: 29rem;
  background-color: white;
  padding: 2.5rem 1.5rem 1.5rem;
  border: 1px solid #eaefff;
  border-radius: 0.5rem;
`;

const Button = styled(Link)`
  margin-top: 1rem;
  background-color: #1656e9;
  color: #fbfbfb;
  border-radius: 1.5rem;
  padding: 0.875rem 1.25rem;
  font-weight: 600;
  transition: all 0.2s ease-in;
  display: inline-block;
  &:hover {
    color: white;
    background-color: #0f44bb !important;
  }
`;

const StyledLink = styled(Link)`
  color: #1f5dea !important;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;

  svg {
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
    transform: rotate(180deg);
  }
`;

const StyledTypography = styled(Typography)`
  min-height: 6rem;
`;

const CardExpanded = ({
  title,
  description,
  tool_slug,
  cta_label,
  button_label,
  starting,
  price,
  currency,
}) => {
  return (
    <Article>
      <Typography
        color="#253858"
        fontWeight="medium"
        fontSize="1.5rem"
        mb="1.5rem"
      >
        {title}
      </Typography>
      <StyledTypography color="##3F517F" mb="1.5rem">
        {description}
      </StyledTypography>
      <Typography
        color="#253858"
        fontSize="1.25rem"
        fontWeight="500"
        mb="0.5rem"
      >
        {starting}
      </Typography>
      <Typography fontWeight="500" fontSize="2rem" mb="2rem" color="#253858">
        {price} {currency}
      </Typography>

      <StyledLink to={`/products/${tool_slug}`}>
        {button_label} <Icon variant="arrow" />
      </StyledLink>
      {cta_label && (
        <Button to={`/brief?briefing=${tool_slug}`}>{cta_label}</Button>
      )}
    </Article>
  );
};

export default CardExpanded;
